.settings-box {
    padding: 16px;
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: calc(100vh - 100px);
    align-items: center;
    margin-bottom: 90px;
}

.settings-box h1 {
    text-align: center;
    color: #5A5A5AE3;
    margin-top: 12px;
}

.settings-box .textfield {
    width: 90%;
    margin-bottom: 24px;
}