.save-button-wrapper {
    height: 80px;
    width: 80px;
    position: fixed;
    bottom: 20px;
    right: 20px;
    border-radius: 50%;
    background-color: #606060;
    text-align: center;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}