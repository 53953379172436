.box-1{
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 80%;
}

.box-2 {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    padding-top: 2rem;
    padding-bottom: 2rem;
    flex: 1 1 0%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

}

.box-3{
    margin-bottom: 2rem;
    font-size: 2.25rem;
    line-height: 2.5rem;
    font-weight: 700;
    color: white;
}

.box-4 {
    display: flex;
    padding-left: 0.75rem;
    padding-right: 0.75rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
    align-items: center;
    border-radius: 2rem;
    width: 100%;
    margin: 1rem;
    border: none;
}
.box-5{
    font-size: 0.875rem;
    line-height: 1.25rem;
}

.box-6 {
    flex: 1 1 0;
}

.box-7{
    position: relative;
    padding: 0.75rem;
}

.box-8 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.box-9 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.box-10 {
    margin-bottom: 0.25rem;
    text-align: center;
}

.box-11 {
    font-weight: 700;
}

.box-12 {
    font-size: 0.75rem;
    line-height: 1rem;

}

.box-13 {
    width: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    margin-top: 1rem;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    align-items: center;
    justify-content: center;
}

.box-14 {
    font-size: 0.75rem;
    line-height: 1rem;

}

.box-15 {
     display: flex;
     gap: 1rem;
     justify-self: center;
     align-items: center;
 }

.box-16 {
    animation: spin 1s linear infinite;

    @keyframes spin {
        from {
            transform: rotate(0deg);
        }
        to {
            transform: rotate(360deg);
        }
    }
}

.box-17 {
    display: flex;
    gap: 0.75rem;
    justify-self: end;
    align-items: center;
    margin-left: 10px;
}

.box-18 {
    position: absolute;
    right: 0;
    left: 0;
    height: 0.25rem;
}

.box-19{
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: 0;
    width: 100%;
    height: 100%;
    background-color: white;
    transition-property: all;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 300ms;
    appearance: none;
    cursor: pointer;
}

.box-20 {
    margin: 0;
    border-radius: 1rem;
    height: 0.5rem;
    background-color: #374151;
    appearance: none;
    cursor: pointer;
    width: 80px;

}

.box-21 {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0.5rem;
    border: none;
    min-width: 30px;
    margin-top: 10px;
}