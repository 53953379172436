*{
    box-sizing: border-box;
}

.page-devices {
    height: 100vh;
    width: 100vw;
    background: linear-gradient(#1F5F7D, #216C8F);
    display: flex;
    flex-direction: column;
}

.header-devices {
    margin-top: 20px;
    height: 6vh;
    display: flex;
    justify-content: space-around;
    margin-bottom: 16px;
    align-items: center;

}
.logo-devices {
    height: 100%;
}

.button-add-new-devices {
    background-color: #083D51;
    width: 280px;
    height: 45px;
    max-width: 80%;
    border: solid #979797 1px;
    color: white;
    font-size: 100%;
    margin-bottom: 20px;
    box-shadow: 2px 2px 2px rgba(66, 136, 226, 0.5);
    cursor: pointer;
    display: flex;
    justify-content: space-around;
    align-items: center;
    text-align: center;
    margin-bottom: 20px;
    border-radius: 15px;
}

.button-manage-devices {
    background-color: #083D51;
    width: 280px;
    height: 45px;
    max-width: 80%;
    border: solid #979797 1px;
    color: white;
    font-size: 100%;
    box-shadow: 2px 2px 2px rgba(66, 136, 226, 0.5);
    cursor: pointer;
    display: flex;
    justify-content: space-around;
    align-items: center;
    text-align: center;
    margin-bottom: 20px;
    border-radius: 15px;
}

.manage-devices-selector {
    margin-top: -20px ;
    background-color: #DFDFDF;
    width: 280px;
    max-height: 50vh;
    overflow: auto;
}

.helpers {
    text-align: justify;
    margin: 20px 40px;
    font-size: 12px;
    color:lightgray;
    line-height: 20px
}

.product-devices {
    width: 280px;
    height: 30px;
    border-bottom: solid #000 1px;
    color: #808080;
    cursor: pointer;

}

.new-device-img{
    object-fit: contain;
    max-height: 90%;
}

.left-container-devices{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.right-container-devices{
    width: 30%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.main-devices{
    height: 90%;
    width: 100%;
}

.add-device-input {
    background-color: #DFDFDF;
    color: #808080;
    width: 300px;
    height: 50px;
    border: none;
    border-radius: 7px;
    font-size: 20px;
}

.go-button-devices{
    width: 90px;
    height: 35px;
    margin-top: 24px;
    background-color: #083D51;
    border-radius: 23px;
    border: solid #979797 1px;
    color: white;
    cursor: pointer;
}