.loading-screen-container {
    padding: 16px;
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    justify-content: center;
    height: calc(100vh - 100px);
    /*position: absolute;*/
    /*bottom: 0;*/
}