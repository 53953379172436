.small-info-wrapper {
    width: 90%;
    display: grid;
    grid-template-columns:1fr 1fr;
}

.small-info-label {
    color: #5A5A5AE3;
    grid-column: 1/2;
    margin: 0;
    padding: 4px 0
}

.small-info-info {
    color: #5A5A5AE3;
    grid-column: 2/3;
    margin: 0;
    padding: 4px 0;
    border-left: 2px solid #606060;
}

.large-info-wrapper {
    width: 100%;
    background-color: #C6C6C6;
    border-radius: 10px;
    margin: 12px 0;
    padding: 8px;
}

.large-info-label {
    color: white;
    margin: 0;
}

.large-info-info {
    color: white;
    margin: 0;
}

.j-preview-title {
    margin: 12px;
    color: #606060;
    margin-top: 20px
}

.profile-preview-image {
    width: 40%;
    aspect-ratio: 1;
    border-radius:50%;
    margin-bottom: 24px;
}

.preview-select {
    width: 100%;
    height: 50%;
    align-self: center;
}

.preview-multiple-investigation-title {
    color: #606060;
    text-align: start;
}

.multiple-investigation-header {
    width: 100%;
    display: grid;
    grid-template-columns:2fr 1fr;
}