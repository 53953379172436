*{
    box-sizing: border-box;
}
.page-initial {
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: url('../assets/background.png');
    background-repeat: no-repeat;
    background-size: cover;
}

.modal-initial {
    border-radius: 25px;
    width: 680px;
    height: 430px;
    max-width: 90vw;
    max-height: 80vh;
    background-color: rgba(0, 86, 157, 0.5);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    padding: 24px;
    box-shadow: 0 20px 20px rgb(256 256 256 / 0.2);
    border: solid  #13DFB2 1px;
}

.flexpayz-logo {
    width: 60%;
    min-width: 220px;
}

.website-button {
    background-color: #0F4D9D;
    width: 280px;
    height: 45px;
    max-width: 80%;
    border: solid  #13DFB2 1px;
    color: white;
    font-size: 100%;
    margin-bottom: 20px;
    box-shadow: 2px 2px 2px rgba(256, 256, 256, 0.5);
    cursor: pointer;
    border-radius: 25px;
}

.app-button {
    background-color: #4288E2;
    width: 280px;
    height: 45px;
    max-width: 80%;
    border: solid  #13DFB2 1px;
    color: white;
    font-size: 100%;
    box-shadow: 2px 2px 2px rgba(256, 256, 256, 0.5);
    cursor: pointer;
    border-radius: 25px;
}

.buttons-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
}