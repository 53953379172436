.j-navbar-container {
    width: 100%;
    height: 100px;
    display: flex;
    justify-content: space-around;
    padding: 12px;
    position: sticky;
    top: 0;
    background-color: white;
    z-index: 100;
    box-shadow: 0 4px 6px -6px #222;

}

.j-navbar-segment-wrapper {
    flex: 1 1 0;
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border-radius: 10%;
    margin: 0 4px;
    padding: 2px;
    cursor: pointer;
    background-color: transparent;
    transition: background-color 0.5s;
}

.j-navbar-icon {
    width: 30px;
    margin-bottom: 4px;
}

.j-navbar-label {
    display: block;
    height: fit-content;
    margin: 0;
}

.j-navbar-active-segment {
    background-color: #C6C6C6;
}